<template>
  <div class="home">
    <Nav></Nav>
    <div class="main">
      <div class="content">
        <div class="table_title">账号列表</div>
        <el-table :data="tableList" style="width: 100%;">
          <el-table-column prop="username" label="账号" align="center">
          </el-table-column>
          <!-- <el-table-column prop="name" label="姓名" align="center">
          </el-table-column>
          <el-table-column prop="gender" label="性别" align="center">
            <template slot-scope="scope">
              {{ENUM.gender[scope.row.gender]}}
            </template>
          </el-table-column>
          <el-table-column prop="age" label="年龄" align="center">
          </el-table-column> -->
          <el-table-column prop="institutionName" label="单位" align="center">
          </el-table-column>
          <el-table-column prop="roleType" label="角色" align="center">
            <template slot-scope="scope">
              {{ENUM.role[scope.row.roleType]}}
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope" v-if="scope.row.roleType!=='SUPERADMIN'">
              <el-button size="small" type="primary" @click="modify(scope.row.id)">修改</el-button>
              <el-button size="small" type="warning" @click="del(scope.row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination :current-page.sync="pageNo" :page-size="filter.size" :total="total" layout="total, prev, pager, next" @size-change="handleSizeChange" @current-change="handleCurrentChange">
        </el-pagination>
      </div>
      <div class="bottom">
        <el-button class="addAccount" type="primary" @click="addAccount">添加账号</el-button>
      </div>
    </div>
    <el-dialog :model="form" :title="title" :visible.sync="showDialog" width="440px" @close="resetForm('form')" :close-on-click-modal="false" :close-on-press-escape="false">
      <el-form ref="form" label-position="right" label-width="80px" :model="form" class="formData">
        <el-form-item v-if="mode==='add'" label="账号" prop="username" :rules="[{ required: true, message: '请输入账号', trigger: 'blur' },{ validator:validatorAccount, message: '用户名只能包含0-9、A-Z、a-z，长度为2-12',trigger: 'blur' }]">
          <el-input onKeypress="javascript:if(event.keyCode == 32)event.returnValue = false" v-model="form.username" auto-complete="new-password"></el-input>
        </el-form-item>
        <el-form-item v-if="mode==='add'" label="密码" prop="password" :rules="[{ required: true, message: '请输入密码', trigger: 'blur' },{ validator:validator, message: '密码至少6位数',trigger: 'blur' }]">
          <el-input onKeypress="javascript:if(event.keyCode == 32)event.returnValue = false" type="password" v-model="form.password" auto-complete="new-password"></el-input>
        </el-form-item>
        <el-form-item v-if="mode==='add'" label="重复密码" prop="rePassword" :rules="[{ required: true, message: '请输入密码', trigger: 'blur' },{ validator:validator, message: '密码至少6位数',trigger: 'blur' }]">
          <el-input onKeypress="javascript:if(event.keyCode == 32)event.returnValue = false" type="password" v-model="form.rePassword"></el-input>
        </el-form-item>
        <!-- <el-form-item label="姓名" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="gender" :rules="[{ required: true, message: '请选择性别', trigger: 'change' }]">
          <el-select v-model="form.gender">
            <el-option v-for="item in genderOption" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="年龄" prop="age">
          <el-input v-model.number="form.age"></el-input>
        </el-form-item> -->
        <el-form-item label="所属单位" prop="institutionName" :rules="[{ required: true, message: '请输入所属单位', trigger: 'blur' }]">
          <el-input onKeypress="javascript:if(event.keyCode == 32)event.returnValue = false" v-model="form.institutionName"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <div class="tip">注意：带 <span style="color:red">* </span> 项为必填信息</div>
        <el-button size="small" @click="showDialog = false">取 消</el-button>
        <el-button size="small" type="primary" @click="submitForm('form')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Nav from '@/components/Nav'
import Service from '@/api/service'
import { ENUM } from '@/utils/enum'
export default {
  name: "Account",
  components: {
    Nav
  },
  data() {
    return {
      validatorAccount: null,
      validator: null,
      genderOption: [
        { label: '男', value: 'Male' },
        { label: '女', value: 'Female' }
      ],
      roleOption: [
        { label: '管理员', value: 'SUPERADMIN' },
        { label: '企业', value: 'INSTITUTION' }
      ],
      ENUM: ENUM,
      tableList: [],
      showDialog: false,
      title: '',
      form: {
        age: '',
        gender: '',
        password: '',
        rePassword: '',
        institutionName: '',
        name: '',
        roleType: '',
        username: ''
      },
      filter: {
        page: 0,
        size: 10
      },
      total: 0,
      pageNo: 1,
      mode: '',
      selectId: ''
    }
  },
  created() {
    this.validatorAccount = (rule, value, callback) => {
      let Regex = /^[0-9a-zA-Z]{2,12}$/;
      if (value && !Regex.test(value)) {
        callback(new Error('用户名只能包含0-9、A-Z、a-z，长度为2-12'))
      } else {
        callback();
      }
    };
    this.validator = (rule, value, callback) => {
      let Regex = /^.{6,}$/;
      if (value && !Regex.test(value)) {
        callback(new Error('密码至少6位数'))
      } else {
        callback();
      }
    };
    this.getList()
  },
  methods: {
    async getList() {
      let re = await Service.accountList(this.filter)
      this.tableList = re.content
      this.total = re.totalCount
    },
    addAccount() {
      this.mode = 'add'
      this.form = {
        age: '',
        gender: '',
        password: '',
        rePassword: '',
        institutionName: '',
        name: '',
        roleType: '',
        username: ''
      }
      this.showDialog = true
      this.title = '添加账号'
    },
    async submitForm(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          if (this.form.username && this.form.username.split(" ").join("").length === 0) {
            this.$message.error("账号不能为空");
            return;
          }
          if (this.mode === 'modify') {
            this.form.accountId = this.selectId
            await Service.modifyAccount(this.form)
          } else {
            if (this.form.password !== this.form.rePassword) {
              this.$message.error('两次输入密码不一致！')
              return
            }
            await Service.addAccount(this.form)
          }
          this.$message.success('保存成功')
          this.showDialog = false
          this.getList();
        }
      });
    },
    handleSizeChange(val) {
      this.filter.size = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNo = Number(val)
      this.filter.page = Number(val) - 1;
      this.getList();
    },
    async modify(id) {
      this.mode = 'modify'
      this.selectId = id
      this.form = await Service.accountDetail(id)
      this.showDialog = true
      this.title = '修改账号'
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    async del(id) {
      this.$confirm('确定删除该账号?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await Service.deleteAccount(id)
        this.$message.success('删除成功')
        this.getList();
      })
    }
  }

};
</script>
<style lang="scss" scoped>
.content {
  width: calc(100% - 60px);
  height: calc(100% - 70px);
  background: #fff;
  box-shadow: 4px 4px 9px 0 rgba(0, 0, 0, 0.08);
  position: relative;
  overflow: auto;
  padding: 0 30px;
}
.bottom {
  height: 70px;
  width: 100%;
  position: relative;
}
.table_title {
  padding: 15px 0 10px 0px;
  font-weight: 600;
  color: #666;
}
.addAccount {
  float: right;
  margin-top: 20px;
}
.tip {
  float: left;
  font-size: 10px;
  color: #666;
  margin-left: 20px;
}
</style>
